export default function initialState () {
  return {
    version: 1,
    snackbar: {
      visible: false,
      timeout: 6000,
      message: null
    },
    // session info
    token: null,
    user: {
      id: null,
      name: null,
      phoneNumber: null,
      region: null,
      blocked: null
    }
  }
}
