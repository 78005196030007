import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify.js'
import * as Sentry from '@sentry/vue'
import { SENTRY_DSN, VERSION } from '@/config.js'
// use inter font
import '@fontsource/inter/latin.css'

import { registerSW } from 'virtual:pwa-register'
registerSW({ immediate: true })

const isProd = import.meta.env.MODE === 'production'
Vue.config.productionTip = false

// enable sentry
if (isProd) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/zeus\.tilt\.bike/],
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    release: `tilt-sales-pwa@${VERSION}`
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
