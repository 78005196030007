import request from './utils/request.js'
import {
  CHECK_SALES_USER_QUERY,
  LOGIN_OTP_MUTATION,
  SEND_OTP_SMS_MUTATION,
  RESEND_OTP_SMS_MUTATION,
  USER_PROFILE_QUERY,
  SALES_BUSINESSES_QUERY,
  SALES_LOG_PHOTO_URL_MUTATION,
  CREATE_SALES_LOG_MUTATION,
  SALES_LOGS_QUERY
} from './graphql.js'

export default {
  async checkSalesUser ({ commit, state, dispatch }, payload) {
    const response = await request(CHECK_SALES_USER_QUERY, payload)

    if (response && response.data && response.data.checkSalesUser) {
      if (!response.data.checkSalesUser.exists) {
        commit('SHOW_SNACKBAR', { message: 'A user with this phone number does not exist.' })
      } else if (response.data.checkSalesUser.blocked) {
        commit('SHOW_SNACKBAR', { message: 'Your account has been blocked. Please contact support.' })
      } else {
        commit('SET_USER_PHONE_NUMBER', payload.phoneNumber)
        return response.data.checkSalesUser
      }
    }
  },
  async loginOtp ({ commit, state }, payload) {
    const response = await request(LOGIN_OTP_MUTATION, { input: payload })

    if (response && response.data && response.data.loginSalesUserOtp) {
      commit('SET_USER_SESSION', response.data.loginSalesUserOtp)
      return response.data.loginSalesUserOtp
    }
  },
  async sendOtpSms ({ commit, state, dispatch }, payload) {
    const response = await request(SEND_OTP_SMS_MUTATION, { input: payload })

    if (response && response.data && response.data.sendOtpSms) {
      commit('SHOW_SNACKBAR', { message: 'An OTP has been sent to your phone.' })
      return response.data.sendOtpSms
    }
  },
  async resendOtpSms ({ commit, state }, payload) {
    const response = await request(RESEND_OTP_SMS_MUTATION, { input: payload })

    if (response && response.data && response.data.resendOtpSms && response.data.resendOtpSms.type === 'success') {
      commit('SHOW_SNACKBAR', { message: 'An OTP has been sent to your phone.' })
      return response.data.resendOtpSms
    }
  },
  async getProfile ({ commit, state }, payload) {
    const response = await request(USER_PROFILE_QUERY, payload, state.token)

    if (response && response.data && response.data.currentSalesUser) {
      commit('SET_USER_SESSION', { user: response.data.currentSalesUser })
      return response.data.currentSalesUser
    }
  },
  async getSalesBusinesses ({ commit, state }, payload) {
    const response = await request(SALES_BUSINESSES_QUERY, payload, state.token)

    if (response && response.data && response.data.salesBusinesses) {
      return response.data.salesBusinesses.map(business => business.name)
    }
  },
  async getSalesLogPhotoUrl ({ commit, state, dispatch }, payload) {
    const response = await request(SALES_LOG_PHOTO_URL_MUTATION, {}, state.token)

    if (response && response.data && response.data.createSalesLogPhotoUrl) {
      return response.data.createSalesLogPhotoUrl
    }
  },
  async createSalesLog ({ commit, state, dispatch }, payload) {
    const response = await request(CREATE_SALES_LOG_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createSalesLog) {
      return response.data.createSalesLog
    }
  },
  async getSalesLogs ({ commit, state, dispatch }, payload) {
    const response = await request(SALES_LOGS_QUERY, payload, state.token)

    if (response && response.data && response.data.currentSalesUser && response.data.currentSalesUser.salesLogs) {
      return response.data.currentSalesUser.salesLogs
    }
  },
  logout ({ commit, state }) {
    commit('CLEAR_STATE')
  }
}
